import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(
    private firestore: AngularFirestore,
    private firestorage: AngularFireStorage,
    private localService: LocalService
  ) { }

  getCandidates() {
    return this.firestore.collection(`candidatos`)
    .snapshotChanges()
    .pipe(
      map( snapshot => snapshot.map( doc => ({
            ...doc.payload.doc.data() as any,
            uid: doc.payload.doc.id
          })
        )
      )
    );
  }

  createCandiate(email:string, password:string, consecutivo: number) {
    return this.firestore.doc( `candidatos/${email}`)
    .set( {
      email,
      password,
      consecutivo,
      correoEnviado: false
     } )
  }

  updateCandiateStatusEmail(candidate:any) {
    return this.firestore.doc( `candidatos/${candidate.email}`)
    .update( {
      ...candidate
     } )
  }

  getCandidateLogin(email: string) {
    return this.firestore.doc( `candidatos/${email}`)
    .snapshotChanges()
    .pipe(
      map( snapshot => {
        let dataUser:any = snapshot.payload.data();

        return dataUser?.password;

      }
      )
    );

  }

  getCandidateFicha(email: string) {
    return this.firestore.doc( `candidatos/${email}`)
    .snapshotChanges()
    .pipe(
      map( snapshot => {
        let dataUser:any = snapshot.payload.data();

        return dataUser;

      }
      )
    );

  }

  getCandidate(email: string) {
    return this.firestore.doc( `candidatos/${email}`)
    .snapshotChanges()
    .pipe(
      map( snapshot => {
        let dataUser:any = snapshot.payload.data();

        return {
          ...dataUser
        }

      }
      )
    );

  }

  setCandidateFile(fileName: string, datos:any) {
    return this.firestorage.upload(fileName, datos)
  }

  registerCandidate(info: any) {
    console.log('data candidate ', info);
    return this.firestore.doc( `candidatos/${this.localService.getValue( 'session' )}`)
    .update( {
      register: {
        ...info
      }
     } )
  }
}
