import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-educacion',
    templateUrl: './educacion.component.html',
    styleUrls: ['./educacion.component.scss']
})
export class EducacionComponent implements OnInit {
    ngOnInit() {
        //mainFunction();
    }
}