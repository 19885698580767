import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SupportComponent } from './components/support/support.component';
import { HomeComponent } from './components/home/home.component';
import { MainComponent } from './components/main/main.component';
import { OfertaComponent } from './components/oferta/oferta.component';
import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { SaetamComponent } from './components/saetam/saetam.component';
import { EducacionComponent } from './components/educacion/educacion.component';
import { DocentesComponent } from './components/docentes/docentes.component';
import { EgresadosComponent } from './components/egresados/egresados.component';
import { TransparenciaComponent } from './components/transparencia/transparencia.component';
import { ConvocatoriaComponent } from './components/convocatoria/convocatoria.component';
import { RegistroComponent } from './components/registro/registro.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { RegistroDataComponent } from './components/registro-data/registro-data.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FichaComponent } from './components/ficha/ficha.component';

@NgModule({
  declarations: [
    AppComponent,

    SupportComponent,
    HomeComponent,
    MainComponent,
    OfertaComponent,
    EstudiantesComponent,
    SaetamComponent,
    EducacionComponent,
    DocentesComponent,
    EgresadosComponent,
    TransparenciaComponent,
    ConvocatoriaComponent,
    RegistroComponent,
    RegistroDataComponent,
    FichaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgbModule,
    NgbModalModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
