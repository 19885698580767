import { Component, OnInit } from "@angular/core";


@Component({
  selector: 'app-convocatoria',
  templateUrl: './convocatoria.component.html',
  styleUrls: ['./convocatoria.component.scss']
})
export class ConvocatoriaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
