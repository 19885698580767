import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RegistroComponent } from '../registro/registro.component';

declare var $: any;


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {



  constructor(
    private ngModalService: NgbModal
  ) {

  }
  ngOnInit() {
  }

  openLoginRegister() {


    this.ngModalService.open(RegistroComponent, {

    });


  }
}
