import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-oferta',
    templateUrl: './oferta.component.html',
    styleUrls: ['./oferta.component.scss']
})
export class OfertaComponent implements OnInit {
    ngOnInit() {

    }
}