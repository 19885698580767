import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environment';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';


declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'cbta-site';
  ENVIRONMENT:any = environment;
   ngOnInit() {
     $('#test').click(() => {
       alert('test');
     });

     //this.testPdf();
   }


   testPdf() {
     const doc = new jsPDF();
     doc.text('hello world!', 10, 10);
     doc.save('hello-world.pdf');
   }

}
