import { AfterViewInit, Component, OnInit } from '@angular/core';
import { slideData } from 'src/assets/data/slide.data';

declare var $: any;


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  slideData = slideData;
    ngOnInit() {

    }

    ngAfterViewInit() {
      $('#fh5co-hero .flexslider').flexslider({
        animation: "slide",

        easing: "swing",
        direction: "vertical",

        slideshowSpeed: 5000,
        directionNav: true,
        start: function(){
          setTimeout(function(){
            $('.slider-text').removeClass('animated fadeInUp');
            $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
          }, 500);
        },
        before: function(){
          setTimeout(function(){
            $('.slider-text').removeClass('animated fadeInUp');
            $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
          }, 500);
        }

        });
    }
}
