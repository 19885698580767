import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-saetam',
    templateUrl: './saetam.component.html',
    styleUrls: ['./saetam.component.scss']
})
export class SaetamComponent implements OnInit {
    ngOnInit() {
        //mainFunction();
    }
}