import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-transparencia',
    templateUrl: './transparencia.component.html',
    styleUrls: ['./transparencia.component.scss']
})
export class TransparenciaComponent implements OnInit {
    ngOnInit() {
        //mainFunction();
    }
}