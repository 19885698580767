// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
VARIABLES
supportTime: signals when the site is in support
*/

export const environment = {
  production: false,
  supportTime: false,
  firebaseConfig: {
    apiKey: "AIzaSyBiYjvKqWs89Z-pre4Gc8xSlJ4TTedadaU",
    authDomain: "cbta-page.firebaseapp.com",
    databaseURL: "https://cbta-page.firebaseio.com",
    projectId: "cbta-page",
    storageBucket: "cbta-page.appspot.com",
    messagingSenderId: "473077524069",
    appId: "1:473077524069:web:b376b6d5d546a7c529488c",
    measurementId: "G-CRKKCS3QZ6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
