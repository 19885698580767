import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConvocatoriaComponent } from './components/convocatoria/convocatoria.component';
import { DocentesComponent } from './components/docentes/docentes.component';
import { EducacionComponent } from './components/educacion/educacion.component';
import { EgresadosComponent } from './components/egresados/egresados.component';
import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { HomeComponent } from './components/home/home.component';
import { OfertaComponent } from './components/oferta/oferta.component';
import { RegistroDataComponent } from './components/registro-data/registro-data.component';
import { RegistroComponent } from './components/registro/registro.component';
import { SaetamComponent } from './components/saetam/saetam.component';
import { TransparenciaComponent } from './components/transparencia/transparencia.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'registroRedirect', redirectTo: '/registro', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'oferta', component: OfertaComponent },
  { path: 'estudiantes', component: EstudiantesComponent },
  { path: 'saetam', component: SaetamComponent },
  { path: 'educacion', component: EducacionComponent },
  { path: 'estudiantes', component: EstudiantesComponent },
  { path: 'docentes', component: DocentesComponent },
  { path: 'egresados', component: EgresadosComponent },
  { path: 'transparencia', component: TransparenciaComponent },
  { path: 'Convocatoria', component: ConvocatoriaComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'registroForm', component: RegistroDataComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
