import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { CandidateService } from 'src/app/services/candidate.service';
import { LocalService } from 'src/app/services/local.service';
import { estadosData } from 'src/assets/data/estados.data';
import { estadosMunicipioData } from 'src/assets/data/estado_municipio.data';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registro-data',
  templateUrl: './registro-data.component.html',
  styleUrls: ['./registro-data.component.scss']
})
export class RegistroDataComponent implements OnInit {

   //Subscribes
   arraySubscriptions: Subscription[] = [];

   registerForm: FormGroup;

   estadosData:any = estadosData;
  estadoMunicipioData:any = estadosMunicipioData;
   municipiosEstudiante:any = estadosMunicipioData['Morelos'];
   municipiosSecundaria:any = estadosMunicipioData['Morelos'];
   datosFormulario = new FormData();

   nombreArchivo = '';
   tipoArchivo = '';
   publicUrl:string;
   email: string;

   @Output() processEvent:EventEmitter<string> = new EventEmitter<string>();

  constructor(private localService: LocalService,
    private router: Router,
    private fb: FormBuilder,
    private candidatesService: CandidateService,
    private firebaseStorage: AngularFireStorage,
    ) { }

  ngOnInit(): void {
    if(!this.localService.existKey( 'session' )) this.router.navigate(['registro'])
    this.loadRegisterForm()
  }

  loadRegisterForm() {
    if(this.registerForm === null || this.registerForm === undefined){
      console.log('no esta definido', this.registerForm)

    }
    else return;
    this.registerForm = this.fb.group({
      // Personal
      fotoUsuario: ['', Validators.required],
      apellidoPaternoEstudiante: ['', Validators.required],
      apellidoMaternoEstudiante: ['', Validators.required],
      nombreEstudiante: ['', Validators.required],
      lugarNacimiento: ['', Validators.required],
      fechaNacimiento: ['', Validators.required],
      curp: ['', Validators.required],
      sexo: ['F', Validators.required],

      //Contacto
      celularEstudiante: ['', Validators.required],
      telefonoCasa: [''],
      email: [''],

      // Direccion estudiantes
      calleNumero: ['', Validators.required],
      estadoEstudiante: ['Morelos', Validators.required],
      municipioEstudiante: ['Yautepec', Validators.required],
      codigoPostalEstudiante: ['', Validators.required],
      pobladoEstudiante: ['', Validators.required],
      coloniaEstudiante: ['', Validators.required],

      // tutor
      apellidoPaternoTutor: ['', Validators.required],
      apellidoMaternoTutor: ['', Validators.required],
      nombreTutor: ['', Validators.required],
      celularTutor: ['', Validators.required],

      // academica
      nombreSecundaria: ['', Validators.required],
      estadoSecundaria: ['Morelos', Validators.required],
      municipioSecundaria: ['Yautepec', Validators.required],

      pobladoSecundaria: ['', Validators.required],
      coloniaSecundaria: ['', Validators.required],
      claveSecundaria: ['', Validators.required],
      promedio: ['', Validators.required],
      carrera: ['', Validators.required],

      //intereses personales
      deporte: ['', Validators.required],
      habilidades: ['', Validators.required],
      apectos: ['', Validators.required],

      // Medica
      cronicas: ['', Validators.required],
      socioEmocionales: ['', Validators.required],
      alergias: ['', Validators.required],
      covid: ['', Validators.required]
    });

    this.loadData();
  }

  loadData() {
    this.arraySubscriptions.push(
      this.registerForm.get('estadoEstudiante').valueChanges.subscribe(result => {
        if(result) return;
        console.log('estado estudiante ',result, this.estadoMunicipioData[result][0])
        this.municipiosEstudiante = this.estadoMunicipioData[result];
        this.registerForm.get('municipioEstudiante').setValue(this.estadoMunicipioData[result][0]);
      })
    );

    this.arraySubscriptions.push(
      this.registerForm.get('estadoSecundaria').valueChanges.subscribe(result => {
        if(result) return;
        console.log('estado estudiante ',result, this.estadoMunicipioData[result][0])
        this.municipiosSecundaria = this.estadoMunicipioData[result];
        this.registerForm.get('municipioSecundaria').setValue(this.estadoMunicipioData[result][0]);
      })
    );
  }

  register() {
    console.log(' values ', this.registerForm.value);

    this.uploadFile().subscribe(result => {
     // this.candidatesService.getCandidate(this.registerForm.value.email).subscribe(candidate => {
        // console.log('candidate register', candidate)
        this.candidatesService.registerCandidate(this.registerForm.value)
        .then( credenciales => {
          console.log(credenciales);
          this.localService.removeItem('session');

          this.processEvent.emit('success');
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Registro exitoso',
          //   text: 'Registro finalizado con exito'
          // });

        } )
        .catch(err => this.processEvent.emit(err.message));
      })

    //})

  }



  logout() {

    this.localService.removeItem( 'session');
    this.router.navigate(['registro'])
  }

  getUserFile(event) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        console.log('file data', event.target.files[i]);
        this.nombreArchivo = event.target.files[i].name;
        this.tipoArchivo = event.target.files[i].type;
        this.datosFormulario.delete('archivo');
        this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name)
      }
    }
  }

  uploadFile() {
    let extension = this.tipoArchivo === 'image/png' ? 'png' : 'jpg';
    let archivo = this.datosFormulario.get('archivo');
    // let referencia = this.firebaseStorage.ref(`candidatos/${this.localservice.getValue( 'session' )}/${ this.nombreArchivo }`);
    return this.firebaseStorage.upload(`candidatos/${this.localService.getValue( 'session' )}.${ extension }`, archivo).snapshotChanges();
  }


}
