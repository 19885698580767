import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-docentes',
    templateUrl: './docentes.component.html',
    styleUrls: ['./docentes.component.scss']
})
export class DocentesComponent implements OnInit {
    ngOnInit() {
        //mainFunction();
    }
}