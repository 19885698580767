import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-egresados',
    templateUrl: './egresados.component.html',
    styleUrls: ['./egresados.component.scss']
})
export class EgresadosComponent implements OnInit {
    ngOnInit() {
        //mainFunction();
    }
}