export const slideData = [
  {
    url: '/assets/img/students.png',
    tittle: 'Manual de Estudiante Curso Propedéutico 2022',
    subtitle: 'Español',
    button: 'Descargar',
    target: '../../../assets/files/español.pdf'
  },
  {
    url: '/assets/img/slider_1.png',
    tittle: 'Manual de Estudiante Curso Propedéutico 2022',
    subtitle: 'Matemáticas',
    button: 'Descargar',
    target: '../../../assets/files/matematicas.pdf'
  }
  /*,
  {
    url: '/assets/img/students.png',
    tittle: 'Guía de estudio 2021',
    subtitle: '¡Descargala ya!',
    button: 'Descargar',
    target: '../../../assets/files/GUIA_DE_ESTUDIO_EDIEMS_2021.pdf'
  },
  {
    url: '/assets/img/slider_1.png',
    tittle: 'Convocatoria ingreso CBTA 193 2021',
    subtitle: 'Descarga la convocatoria',
    button: 'Descargar',
    target: '../../../assets/files/convocatoria2021.pdf'
  }*/

]
