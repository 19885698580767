import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';
import { CandidateService } from 'src/app/services/candidate.service';
import { LocalService } from 'src/app/services/local.service';
import { estadosData } from 'src/assets/data/estados.data';
import { estadosMunicipioData } from 'src/assets/data/estado_municipio.data';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
  //Reactive forms

  loginForm: FormGroup;

  //Subscribes
  arraySubscriptions: Subscription[] = [];

  // Funcionalidad
  estadosData: any = estadosData;
  estadoMunicipioData: any = estadosMunicipioData;
  isLogged: boolean = false;
  datosFormulario = new FormData();
  nombreArchivo = '';
  tipoArchivo = '';
  publicUrl: string;
  email: string;
  registerContent: any;

  municipiosEstudiante: any = estadosMunicipioData['Morelos'];
  municipiosSecundaria: any = estadosMunicipioData['Morelos'];

  @ViewChild('buttonClose') buttonClose;

  constructor(
    private fb: FormBuilder,
    private candidatesService: CandidateService,
    private localservice: LocalService,
    private firebaseStorage: AngularFireStorage,
    private router: Router,
    private ngModalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.loadLoginForm();




  }

  loadLoginForm() {


    this.loginForm = this.fb.group({
      correo: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }







  login() {

    if (this.loginForm.invalid) return;

    let { correo, password } = this.loginForm.value;

    this.candidatesService.getCandidateLogin(correo)
      .subscribe(result => {
        console.log('result login', result);
        if (result === undefined) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El usuario ingresado no existe'
          });
          return;
        }

        if (result !== password) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El password ingresado es incorrecto'
          });
          return;
        }

        this.localservice.setValue('session', correo);

        this.closeModal();
        this.openModalForm();


      }, err => Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.message
      }))

  }

  openLoginRegister(content, register) {
    this.registerContent = register;
    this.ngModalService.open(content, { backdropClass: 'light-blue-backdrop' });
  }

  closeModal() {
    let button: HTMLElement = document.getElementById('buttonClose')
    button.click();
  }


  openModalForm() {
    this.ngModalService.open(this.registerContent, {
      backdropClass: 'light-blue-backdrop',
      backdrop: 'static',
      size: 'lg'
    });
  }

  registerEventResult(event) {
    if (event === 'success') {
      let button: HTMLElement = document.getElementById('buttonCloseRegister')
      button.click();
      setTimeout(() => {
        Swal.fire({
          icon: 'success',
          title: 'Registro exitoso',
          text: 'Registro finalizado con exito'
        });
      }, 1000);
    }
    else Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: event
    })
  }



}
